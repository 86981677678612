exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-alpine-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/en/alpine.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-alpine-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-archlinux-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/en/archlinux.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-archlinux-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-centos-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/en/centos.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-centos-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-debian-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/en/debian.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-debian-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-kali-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/en/kali.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-kali-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-mysql-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/en/mysql.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-mysql-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-nodejs-release-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/en/nodejs-release.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-nodejs-release-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-rocky-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/en/rocky.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-rocky-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-ubuntu-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/en/ubuntu.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-en-ubuntu-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-almalinux-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/almalinux.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-almalinux-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-alpine-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/alpine.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-alpine-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-anaconda-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/anaconda.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-anaconda-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-anolis-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/anolis.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-anolis-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-archlinux-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/archlinux.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-archlinux-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-archlinuxcn-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/archlinuxcn.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-archlinuxcn-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-bioconductor-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/bioconductor.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-bioconductor-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-centos-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/centos.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-centos-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-centos-vault-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/centos-vault.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-centos-vault-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cpan-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/CPAN.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cpan-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cran-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/CRAN.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cran-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-crates-io-index-git-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/crates.io-index.git.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-crates-io-index-git-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ctan-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/CTAN.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ctan-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cygwin-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/cygwin.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cygwin-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-debian-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/debian.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-debian-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-debian-security-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/debian-security.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-debian-security-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-deepin-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/deepin.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-deepin-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-docker-ce-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/docker-ce.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-docker-ce-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-elpa-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/elpa.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-elpa-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-elrepo-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/elrepo.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-elrepo-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-epel-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/epel.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-epel-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-fedora-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/fedora.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-fedora-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/gentoo.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-portage-git-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/gentoo-portage.git.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-portage-git-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-portage-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/gentoo-portage.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-portage-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-glibc-git-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/glibc.git.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-glibc-git-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gnu-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/gnu.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gnu-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-homebrew-git-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/homebrew.git.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-homebrew-git-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ius-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/ius.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ius-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-kali-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/kali.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-kali-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-linux-git-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/linux.git.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-linux-git-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-linuxmint-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/linuxmint.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-linuxmint-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-llvm-apt-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/llvm-apt.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-llvm-apt-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-manjaro-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/manjaro.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-manjaro-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-msys-2-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/msys2.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-msys-2-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-mysql-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/mysql.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-mysql-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-nodejs-release-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/nodejs-release.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-nodejs-release-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-npm-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/npm.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-npm-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-openeuler-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/openeuler.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-openeuler-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-opensuse-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/opensuse.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-opensuse-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-openwrt-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/openwrt.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-openwrt-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-packman-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/packman.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-packman-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-pypi-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/pypi.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-pypi-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-raspberrypi-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/raspberrypi.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-raspberrypi-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-raspbian-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/raspbian.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-raspbian-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rocky-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/rocky.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rocky-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ros-2-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/ros2.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ros-2-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ros-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/ros.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ros-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rosdistro-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/rosdistro.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rosdistro-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rustup-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/rustup.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rustup-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ubuntu-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/ubuntu.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ubuntu-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ubuntu-ports-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/home/mirror/jcutmirror/docs/zh/ubuntu-ports.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ubuntu-ports-mdx" */),
  "component---src-templates-news-tsx-content-file-path-news-en-221115-hello-mirror-mdx": () => import("./../../../src/templates/news.tsx?__contentFilePath=/home/mirror/jcutmirror/news/en/221115_hello_mirror.mdx" /* webpackChunkName: "component---src-templates-news-tsx-content-file-path-news-en-221115-hello-mirror-mdx" */),
  "component---src-templates-news-tsx-content-file-path-news-en-240604-centos-eol-mdx": () => import("./../../../src/templates/news.tsx?__contentFilePath=/home/mirror/jcutmirror/news/en/240604_centos_eol.mdx" /* webpackChunkName: "component---src-templates-news-tsx-content-file-path-news-en-240604-centos-eol-mdx" */),
  "component---src-templates-news-tsx-content-file-path-news-zh-221115-hello-mirror-mdx": () => import("./../../../src/templates/news.tsx?__contentFilePath=/home/mirror/jcutmirror/news/zh/221115_hello_mirror.mdx" /* webpackChunkName: "component---src-templates-news-tsx-content-file-path-news-zh-221115-hello-mirror-mdx" */),
  "component---src-templates-news-tsx-content-file-path-news-zh-240604-centos-eol-mdx": () => import("./../../../src/templates/news.tsx?__contentFilePath=/home/mirror/jcutmirror/news/zh/240604_centos_eol.mdx" /* webpackChunkName: "component---src-templates-news-tsx-content-file-path-news-zh-240604-centos-eol-mdx" */),
  "component---src-templates-news-tsx-content-file-path-news-zh-240620-change-mirror-mdx": () => import("./../../../src/templates/news.tsx?__contentFilePath=/home/mirror/jcutmirror/news/zh/240620_change_mirror.mdx" /* webpackChunkName: "component---src-templates-news-tsx-content-file-path-news-zh-240620-change-mirror-mdx" */)
}

